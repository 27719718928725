import { SEOProps, StructuredDataTypes } from "types";

export const getStructuredData = (
  type: StructuredDataTypes,
  data: SEOProps
) => {
  switch (type) {
    case "article":
      return {
        "@context": "https://schema.org",
        "@type": "NewsArticle",
        headline: data.title,
        image: [data.image],
        datePublished: data.datePublished,
        author: [
          {
            "@type": "Person",
            name: data.authorName,
          },
        ],
      };
    case "job":
      return {
        "@context": "https://schema.org/",
        "@type": "JobPosting",
        title: data.title,
        description: data.description,
        datePosted: data.datePublished,
        hiringOrganization: {
          "@type": "Organization",
          name: "Copper Brass Limited",
          sameAs: data.siteUrl,
          logo: data.logo,
        },
        jobLocation: {
          "@type": "Place",
          address: {
            "@type": "Text",
            addressRegion: data.city,
            addressCountry: data.country,
          },
        },
      };
    case "webpage":
      return {
        "@context": "http://schema.org",
        "@type": "WebPage",
        url: data.slug,
        name: data.title,
        headline: data.title,
        inLanguage: data.lang,
        description: data.description,
        mainEntityOfPage: data.siteUrl,
        datePublished: data.datePublished,
        image: {
          "@type": "ImageObject",
          url: data.image,
        },
      };
    default:
      return;
  }
};
