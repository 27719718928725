import React from "react";
import Helmet from "react-helmet";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";

import appData from "../../../app.json";
import { SEOProps, StructuredDataTypes } from "types";

import { getStructuredData } from "helpers/utils/structuredData";

const query = graphql`
  query GetSiteMetadata {
    site {
      siteMetadata {
        title
        description
        siteUrl
        image
      }
    }
  }
`;

const SEO: React.FC<SEOProps> = ({
  meta,
  slug,
  lang,
  type,
  city,
  image,
  title,
  country,
  authorName,
  description,
  datePublished,
}) => {
  return (
    <StaticQuery
      query={query}
      render={(data) => {
        const { siteMetadata } = data.site;
        const metaURL = slug
          ? `${siteMetadata.siteUrl}${slug}`
          : siteMetadata.siteUrl;
        const metaDescription = description || siteMetadata.description;
        const metaImage = image || `${siteMetadata.image}`;

        const formattedData = {
          lang,
          city,
          country,
          type,
          authorName,
          title: title,
          slug: metaURL,
          datePublished,
          image: metaImage,
          logo: siteMetadata.image,
          description: metaDescription,
          siteUrl: siteMetadata.siteUrl,
        };

        return (
          <Helmet
            htmlAttributes={{ lang }}
            {...(title
              ? {
                  titleTemplate: `%s — ${siteMetadata.title}`,
                  title,
                }
              : {
                  title: `${siteMetadata.title} — ${siteMetadata.description}`,
                })}
            meta={[
              {
                property: "og:site_name",
                content: "Brass",
              },
              {
                property: "og:type",
                content: type || "website",
              },
              {
                name: "description",
                content: metaDescription,
              },
              {
                property: "og:description",
                content: metaDescription,
              },
              {
                name: "twitter:description",
                content: metaDescription,
              },
              {
                name: "twitter:card",
                content: "summary_large_image",
              },
              {
                property: "al:web:url",
                content: metaURL,
              },
              {
                property: "og:url",
                content: metaURL,
              },
              {
                property: "twitter:url",
                content: metaURL,
              },
              {
                name: "twitter:creator",
                content: appData.social.twitter,
              },
              {
                property: "title",
                content: title || siteMetadata.title,
              },
              {
                property: "og:title",
                content: title || siteMetadata.title,
              },
              {
                name: "twitter:title",
                content: title || siteMetadata.title,
              },
              {
                name: "twitter:site",
                content: "@brassbanking",
              },
              {
                property: "og:image",
                content: metaImage,
              },
              {
                name: "twitter:image:src",
                content: metaImage,
              },
              {
                name: "robots",
                content: `index,follow${
                  type === "webpage" ? "" : ",max-image-preview:large"
                }`,
              },
            ].concat(meta)}
          >
            {
              <script type="application/ld+json">
                {JSON.stringify(
                  getStructuredData(type as StructuredDataTypes, formattedData)
                )}
              </script>
            }
          </Helmet>
        );
      }}
    />
  );
};

SEO.defaultProps = {
  meta: [],
  title: "",
  slug: "",
  type: "webpage",
  lang: "en",
};

SEO.propTypes = {
  description: PropTypes.string,
  image: PropTypes.string,
  meta: PropTypes.array,
  slug: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default SEO;
